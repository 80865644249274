import React , { useState } from 'react'
import image from '../../assets/images/items/2.jpg';
import single from '../../assets/images/blog/AI-blog-1.png';
import image1 from '../../assets/images/blog/AI-blog-2.png';
// import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../navbar-new';
import ModalVideo from 'react-modal-video'

import {LuClock, LuCalendarDays} from "../../assets/icons/vander"
import { blogData } from '../../data/data';

export default function BlogDetail() {
const { id,title } = useParams();
const [isOpen, setOpen] = useState(false)
    const blog = blogData.find((blogs) => blogs.id === parseInt (id));
    return (
        <>
            <Navbar />
            <section className="relative pt-32 md:pb-24 pb-16">
                <div className="container">
                    <div className="md:flex justify-center" style={{marginTop:'-60px'}}>
                        <div className="lg:w-3/5">
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl "><span className='bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text'>{blog?.title ? blog?.title  : ""}</span></h3>
                            {/* <h5 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl">{blog?.title ? blog?.title  : ""}</h5> */}
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content1 ? blog?.content1  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content2 ? blog?.content2  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content3 ? blog?.content3  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content4 ? blog?.content4  : ""}</p>      

                            <div className="mt-6">
                                <Link to="https://checkout.square.site/merchant/MLR47B9DMQFVB/checkout/ZZS6PYU3KSQXRYQNQN6MEYAD" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center">Enroll Now to Secure Your Future</Link>
                            </div>             

                            {/* <div className="flex mt-5">
                                <span className="font-medium inline-flex items-center"><LuCalendarDays className="me-1"/>{blog?.date ? blog?.date : "20th October, 2022"}</span>
                                <span className="ms-3 font-medium inline-flex items-center"><LuClock className="me-2"/>5 min read</span>
                            </div> */}

                            {/* <div className="flex items-center mt-5">
                                <div className="relative inline-block">
                                    <img src={image} className="h-14 rounded-full" alt="" />
                                </div>

                                <div className="ms-3">
                                    <Link to="/creator-profile" className="font-semibold block hover:text-violet-600">Michael Williams</Link>
                                    <span className="text-slate-400 text-sm block mt-1">@FunnyGuy</span>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="md:flex justify-center mt-6">
                        <div className="lg:w-full">
                            <div className="relative" 
                            style={{padding:"0px"}}
                            >
                               {blog.id ===1 && 
                            <>
                            <div className='md:flex justify-center'>
                             <h3 className="font-bold lg:leading-snug leading-snug text-3xl lg:text-3xl " style={{marginBottom:'50px'}}><span className='bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text'>{blog?.video ? blog?.video  : ""}</span></h3>
                            </div>
                            <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />
                            <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                            <Link to="#" onClick={() => setOpen(true)} data-id="5j5bVrPRqbk" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-violet-600">
                                <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                            </Link>
                         </div>
                         <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="5j5bVrPRqbk" onClose={() => setOpen(false)} />
                         </>
                                }
                               {blog.id ===2 && <>
                               <div className='md:flex justify-center'>
                                <h3 className="font-bold lg:leading-snug leading-snug text-3xl lg:text-3xl " style={{marginBottom:'50px'}}><span className='bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text'>{blog?.video ? blog?.video  : ""}</span></h3>
                               </div>
                               <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />
                               <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                               <Link to="#" onClick={() => setOpen(true)} data-id="NzPz9TtZkuI" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-violet-600">
                                   <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                               </Link>
                            </div>
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="NzPz9TtZkuI" onClose={() => setOpen(false)} />
                            </>}
                               {blog.id ===3 &&  <>
                               <div className='md:flex justify-center'>
                                <h3 className="font-bold lg:leading-snug leading-snug text-3xl lg:text-3xl " style={{marginBottom:'50px'}}><span className='bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text'>{blog?.video ? blog?.video  : ""}</span></h3>
                               </div>
                               <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />
                               <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                               <Link to="#" onClick={() => setOpen(true)} data-id="1rI9Gqq_36U" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-violet-600">
                                   <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                               </Link>
                            </div>
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="1rI9Gqq_36U" onClose={() => setOpen(false)} />
                            </>}
                               {blog.id ===4 &&
                               <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />
                               }
                               {blog.id ===5 &&
                               <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />}
                               {blog.id ===6 &&  <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />}
                               {blog.id ===7 &&  <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />}
                               {blog.id ===8 &&  <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />}
                               {blog.id ===9 &&  <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />}
                                {/* <img src={blog?.image ? blog?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" /> */}
                                {/* <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link to="#" data-type="youtube" data-id="S_CGed6E610"
                                        className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-violet-600">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="md:flex justify-center mt-6">
                        <div className="lg:w-3/5">

                            <h5 className="text-lg font-semibold mt-4">{blog?.title2 ? blog?.title2  : ""}</h5>

                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content5 ? blog?.content5  : ""}</p>

                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content6 ? blog?.content6  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content7 ? blog?.content7  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content8 ? blog?.content8  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content9 ? blog?.content9  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content10 ? blog?.content10  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content11 ? blog?.content11  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content12 ? blog?.content12  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content13 ? blog?.content13  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content14 ? blog?.content14  : ""}</p>
                            <h5 className="text-lg font-semibold mt-4">{blog?.title3 ? blog?.title3  : ""}</h5>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content15 ? blog?.content15  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content16 ? blog?.content16  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content17 ? blog?.content17  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content18 ? blog?.content18  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content19 ? blog?.content19  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content20 ? blog?.content20  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content21 ? blog?.content21  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content28 ? blog?.content28  : ""}</p>
                            <p className="text-slate-400 max-w-xl mt-4">{blog?.content29 ? blog?.content29  : ""}</p>
                            {blog.id==4 && (
                                <>
                                <div id="testimonial-container">
                              <p class="testimonial">{blog?.content22 ? blog?.content22  : ""}</p>
                              <p class="name">{blog?.content23 ? blog?.content23  : ""}</p>
                              <p class="name">{blog?.content231 ? blog?.content231  : ""}</p>
                            </div>

                            <div id="testimonial-container">
                              <p class="testimonial">{blog?.content24 ? blog?.content24  : ""}</p>
                              <p class="name">{blog?.content25 ? blog?.content25  : ""}</p>
                              <p class="name">{blog?.content251 ? blog?.content251  : ""}</p>
                            </div>

                            <div id="testimonial-container">
                              <p class="testimonial">{blog?.content26 ? blog?.content26  : ""}</p>
                              <p class="name">{blog?.content27 ? blog?.content27  : ""}</p>
                              <p class="name">{blog?.content271 ? blog?.content271  : ""}</p>
                            </div>
                            </>
                            )}
                            
                            
                            

                        </div>

                        
                    </div>
                </div>
            </section>

            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
