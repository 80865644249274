import React, { useReducer } from 'react'
import Navbar from '../../components/navbar'
import { Link as Link2 } from 'react-scroll';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import ai from '../../assets/images/FAQ.png'
import { Link } from 'react-router-dom';

export default function HelpcenterFaqs() {
    const accordionData = [
        {
            title: 'What topics are covered in this course ?',
            content: 'The course covers a range of topics from AI basics to advanced applications, including data analytics, ChatGPT implementation, industry-specific AI strategies, and ethical considerations in AI.'
        },
        {
            title: 'Who is this course designed for ?',
            content: "It's ideal for business professionals, entrepreneurs, and anyone interested in leveraging AI for business growth and efficiency."
        },
        {
            title: 'Do I need prior experience in AI or programming ?',
            content: 'No, the course is structured to accommodate beginners while also providing value for those with some AI background.'
        },
        {
            title: 'How long is the course ?',
            content: 'The course consists of over 200 lessons, which can be completed at your own pace.'
        },
    ]

    const accordionData1 = [
        {
            title: 'What kind of support is available during the course ?',
            content: 'Students have access to one-on-one coaching sessions, an exclusive online community, and regular Q&A sessions.'
        },
        {
            title: 'Are there any technical requirements to take this course ?',
            content: "You'll need a computer with internet access. Any additional software or tools required will be provided or recommended within the course."
        },
        {
            title: 'Is there a certification upon completion ?',
            content: 'Yes, participants will receive a certificate of completion at the end of the course.'
        },
        {
            title: 'Can I access the course materials after completion?',
            content: 'Yes, you will have lifetime access to the course materials, including updates.'
        },
        {
            title: "What if I'm not satisfied with the course?",
            content: 'We offer a money-back guarantee for a 1 year after enrollment.'
        },
    ]

    

    const initialState = {
        activeIndex: 0,
        activeGeneral: 0,
        activePayment: 0,
        activeSupport: 0
    };

    const toggleAccordion = (category, index) => {
        dispatch({ type: 'SELECT_CATEGORY', payload: { category: category, index: index } });
    };

    const reducerMethod = (state, action) => {
        switch (action.payload.category) {
            case 'active':
                return {
                    ...state,
                    activeIndex: action.payload.index
                };
            case 'general':
                return {
                    ...state,
                    activeGeneral: action.payload.index
                };
            // case 'payments':
            //     return {
            //         ...state,
            //         activePayment: action.payload.index
            //     };
            // case 'support':
            //     return {
            //         ...state,
            //         activeSupport: action.payload.index
            //     };
            default:
                return state
        }
    }
    const [state, dispatch] = useReducer(reducerMethod, initialState);
    // const ai = 'https://hostedimages-cdn.aweber-static.com/MTgzODI3OQ==/optimized/4cfc1a535c8e4ee791c3536dc2f231b7.png';


    return (
        <>

            {/* <Navbar /> */}
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg10.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl ">❓ <span className='bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text'>Got </span> Questions</h3>
                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="#">Find Answers in Our Comprehensive FAQ Section! 📚</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/helpcenter-overview">Helpcenter</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">FAQs</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-5">
                        
                            <div className="rounded-md shadow dark:shadow-gray-800 p-6 sticky top-20">
                                <ul className="list-none sidebar-nav mb-0 py-0" id="navmenu-nav">
                                    <li className="navbar-item p-0"><Link2 to="tech" spy={true} activeClass="active"
                                        smooth={true} className="text-base font-medium navbar-link">Course Related Questions</Link2></li>
                                    <li className="navbar-item mt-3 p-0"><Link2 to="general" spy={true} activeClass="active"
                                        smooth={true} className="text-base font-medium navbar-link">General Questions</Link2></li>
                                    {/* <li className="navbar-item mt-3 p-0"><Link2 to="payment" spy={true} activeClass="active"
                                        smooth={true} className="text-base font-medium navbar-link">Payments Questions</Link2></li>
                                    <li className="navbar-item mt-3 p-0"><Link2 to="support" spy={true} activeClass="active"
                                        smooth={true} className="text-base font-medium navbar-link">Support Questions</Link2></li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="lg:col-span-8 md:col-span-7">

                            <div id="tech">
                          
                                <h5 className="text-2xl font-semibold">Course Related Questions</h5>

                                <div id="accordion-collapseone" data-accordion="collapse" className="mt-6">
                                    {accordionData.map((item, index) => (
                                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                                <button onClick={() => toggleAccordion('active', index)} type="button"
                                                    className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-violet-600' : ''}`}
                                                    data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                    <span>{item.title}</span>
                                                    <svg data-accordion-icon className="w-4 h-4 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </h2>
                                            {state.activeIndex === index && (
                                                <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            
                            </div>

                            <div id="general" className="mt-8">
                                <h5 className="text-2xl font-semibold">General Questions</h5>
                                <div id="accordion-collapsetwo" data-accordion="collapse" className="mt-6">
                                    {accordionData1.map((item, index) => (
                                        <div key={index} className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}>
                                            <h2 className="text-base font-medium">
                                                <button onClick={() => toggleAccordion('general', index)} type="button" className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeGeneral === index ? 'bg-gray-50 dark:bg-slate-800 text-violet-600' : ''} `} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                    <span>{item.title}</span>
                                                    <svg data-accordion-icon className={`w-4 h-4 shrink-0 ${state.activeGeneral === index ? 'rotate-180' : ''}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </h2>
                                            {state.activeGeneral === index && (
                                                <div aria-labelledby="accordion-collapse-heading-1">
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* <div id="payment" className="mt-8">
                                <h5 className="text-2xl font-semibold">Payments Questions</h5>

                                <div id="accordion-collapsethree" data-accordion="collapse" className="mt-6">
                                    {accordionData.map((item, index) => (
                                        <div key={index} className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}>
                                            <h2 className="text-base font-medium">
                                                <button onClick={() => toggleAccordion('payments', index)} type="button" className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activePayment === index ? 'bg-gray-50 dark:bg-slate-800 text-violet-600' : ''} `} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                    <span>{item.title}</span>
                                                    <svg data-accordion-icon className={`w-4 h-4 shrink-0 ${state.activePayment === index ? 'rotate-180' : ''}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </h2>
                                            {state.activePayment === index && (
                                                <div aria-labelledby="accordion-collapse-heading-1">
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div id="support" className="mt-8">
                                <h5 className="text-2xl font-semibold">Support Questions</h5>

                                <div id="accordion-collapsefour" data-accordion="collapse" className="mt-6">
                                    {accordionData.map((item, index) => (
                                        <div key={index} className={`relative shadow dark:shadow-gray-700 rounded-md overflow-hidden mt-4`}>
                                            <h2 className="text-base font-medium">
                                                <button onClick={() => toggleAccordion('support', index)} type="button" className={`flex justify-between items-center p-5 w-full font-semibold text-start ${state.activeSupport === index ? 'bg-gray-50 dark:bg-slate-800 text-violet-600' : ''} `} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                    <span>{item.title}</span>
                                                    <svg data-accordion-icon className={`w-4 h-4 shrink-0 ${state.activeSupport === index ? 'rotate-180' : ''}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </h2>
                                            {state.activeSupport === index && (
                                                <div aria-labelledby="accordion-collapse-heading-1">
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16" style={{marginTop:'-70px'}}>
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div>
                                <img src={ai} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                                
                            </div>
                        </div>

                        <div className="lg:col-span-7">
                            <div className="lg:ms-5">
                                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Transform Your Business  <br /> with the Power of AI</h3>
                                <p className="text-slate-400 max-w-xl mt-4" >🔍 <span style={{color:'#38c54d'}}> Explore In-Depth Responses: Delve into detailed explanations to all your queries.</span></p>
                                <p className="text-slate-400 max-w-xl mt-4" >💡 <span style={{color:'#38c54d'}}> Enlightening Insights: Gain clarity on every aspect of the course and its benefits.</span></p>
                                <p className="text-slate-400 max-w-xl mt-4" >🤔 <span style={{color:'#38c54d'}}> Problem-Solving Made Easy: Quick solutions to common concerns and inquiries.</span></p>
                                <p className="text-slate-400 max-w-xl mt-4" >👥 <span style={{color:'#38c54d'}}> Peer Perspectives: Discover frequently asked questions by like-minded professionals.</span></p>
                                <p className="text-slate-400 max-w-xl mt-4" >📘 <span style={{color:'#38c54d'}}> Your Knowledge Base: A treasure trove of information at your fingertips.</span></p><br></br>
                                <p className="text-slate-400 max-w-xl">Unlock the Full Potential of AI: Elevate Your Business to New Heights with Our Comprehensive AI and ChatGPT Masterind Course. Transform the Way You Work, Innovate, and Compete in the Digital Age.</p>
                                <div className="mt-6">
                                    <Link to="https://checkout.square.site/merchant/MLR47B9DMQFVB/checkout/ZZS6PYU3KSQXRYQNQN6MEYAD" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center">Enroll Today to Secure Your Future</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {/* <Footer /> */}
            {/* <Switcher /> */}
        </>
    )
}
