import React, { useState } from 'react'
import Navbar from '../components/navbar'
import Team from '../components/team'
import Blog from '../components/blog'
import Footer from '../components/footer'
import about from '../assets/images/about.jpg';
import ai from '../assets/images/ai-mastermind.png';
import Switcher from '../components/switcher'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import {FaRegEnvelope} from "../assets/icons/vander"

export default function About() {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            {/* <Navbar /> */}
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg2.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl "><span className='bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text'>About </span> Us</h3>

                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Giglink</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div>
                                <img style={{marginTop:50}} src={ai} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                                {/* <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link to="#" onClick={() => setOpen(true)} data-id="S_CGed6E610" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-violet-600">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

                        <div className="lg:col-span-7" >
                            <div className="lg:ms-5">
                                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">What Is The <br /> AI Mastermind Course ?</h3>

                                <span className="text-violet-600 font-medium">This Is Brand New For November 2023</span> 

                                <p className="text-slate-400 max-w-xl">AI Mastermind Course: A Revolutionary Strategy to Harness AI & ChatGPT's Potential. Break the cycle of time and financial limitations, and unlock new realms of success and innovation in your business</p><br></br>
                                <span className="text-violet-600 font-medium">We master ChatGPT and AI tools, allowing us to:</span>
                                <p className="text-slate-400 max-w-xl mt-4">✅ Save 1,000+ work hours</p>
                                <p className="text-slate-400 max-w-xl mt-4">✅ 10X your daily output</p>
                                <p className="text-slate-400 max-w-xl mt-4">✅ 10X profit margins</p>
                                <p className="text-slate-400 max-w-xl mt-4">✅ Scale your business in record time.</p><br></br>
                                <p className="text-slate-400 max-w-xl">Transform Your Business with Strategic Systems and Smart Automations. Unlock substantial revenue growth, gain a standout presence, and triumph in competitive markets. Achieve more with less effort - power your success with efficient operations</p><br></br>
                                <div className="mt-6">
                                    <Link to="https://checkout.square.site/merchant/MLR47B9DMQFVB/checkout/ZZS6PYU3KSQXRYQNQN6MEYAD" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center">Enroll Now to Secure Your Future</Link>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>

                <div className="container lg:pt-24 pt-16">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Voices of Authority: What Experts Are Saying About <br /> AI's Impact and Future</h3><br></br>
                <div id="testimonial-container">
                              <p class="testimonial">Sooner or Later, the U.S will face mounting job losses due to advances in automation, Artificial intelligence, and Robotics.</p>
                              <p class="name">Oren Etzioni</p>
                            </div>

                            <div id="testimonial-container">
                              <p class="testimonial">AI is in a "Golden Age" and solving problems that were once in the realm of Sci-Fi</p>
                              <p class="name">Jeff Bezos</p>
                            </div>
                </div>

                {/* <Team />
                <Blog /> */}

                            
            </section>
            {/* <Footer /> */}
            {/* // <Switcher /> */}
        </>
    )
}
